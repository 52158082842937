import React from 'react';
import { Nutrition, useClientNutritions } from '~/model/Client/Nutritions';
import { useClient } from '~/components/pages/client/context';
import { Loader } from '~/components/common/Loader';
import { TableProvider } from '~/components/common/Table/TableProvider';
import { Card, CardBody } from '~/components/common/Card';
import { Nutritions as Component } from './Nutritions';

const makeInitialState = (nutritions: Nutrition[]) => ({
  rows: nutritions.map((nutrition) => ({ id: nutrition.title, expanded: false })),
});

export const Nutritions = () => {
  const { clientId } = useClient();
  const { isError, isLoading, data } = useClientNutritions(clientId);

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <>Error has occured</>;
  }

  return (
    <TableProvider initialState={makeInitialState(data.items)}>
      <Card>
        <CardBody>
          <Component nutritions={data.items} updatedAt={data.updatedAt} />
        </CardBody>
      </Card>
    </TableProvider>
  );
};
